import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/agent/workspace/vOps_provision-documentation_dev/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Amazon Web Services`}</h1>
    <p>{`Provision Portal webpage and execution for Servers and Devices relies heavily on AWS cloud resources. We will outline those here for better understanding of each service.`}</p>
    <p>{`Resources with a `}<inlineCode parentName="p">{`*`}</inlineCode>{` `}<strong parentName="p">{`require`}</strong>{` Customers to allow traffic through their Firewalls.`}</p>
    <h2>{`Provision Portal Webpages*`}</h2>
    <p>{`Both the Portal (provision.bddevops.com) and backend API are hosted in AWS. For customers, we leverage both the Portal and API to download SSM Agents.
The Provision Portal webpage only serves as a “notification center” so users don’t have to navigate various AWS accounts to track automation executions.`}</p>
    <h2>{`Systems Manager (SSM)*`}</h2>
    <p>{`AWS SSM is the key execution resource for all things Provision Portal. It is the central piece to remote, automated executions.
SSM is a tool built to manage “instances”, in our case Servers and Devices, at scale using automated scripting.
The automated scripting is done via Automation Documents or Run Command Documents.`}</p>
    <h3>{`Automation Documents`}</h3>
    <p>{`Automation document is a YAML (yet another markup language) or JSON formatted document used to define a set of steps for automated execution.
Each Automation Document can have one or many steps. Every execution of an Automation document is tracked by an `}<inlineCode parentName="p">{`Execution Id`}</inlineCode>{` GUID.
Each step in an Automation Doc is its own singular execution with its own logging, tracked by a `}<inlineCode parentName="p">{`Command Id`}</inlineCode>{` in AWS.
The `}<inlineCode parentName="p">{`Command Id`}</inlineCode>{` is the execution of the step on a single instance. This is what Provision Portal shows as the individual steps within a Workflow step.`}</p>
    <h3>{`Logging`}</h3>
    <p>{`Every log is tied to a `}<inlineCode parentName="p">{`Command Id`}</inlineCode>{`, as an output of an execution. Logs must be defined by the developer or author of what is being executed: choco pack, PowerShell™ script, SQL script…`}</p>
    <p>{`Log output is limited to 48,000 characters via the UI. More verbose, complete logs should be stored on the actual servers or devices.`}</p>
    <h3>{`Requirements for Provision Portal usage:`}</h3>
    <ul>
      <li parentName="ul">{`SSM Agent installed on target system (server or device)`}</li>
      <li parentName="ul">{`Network access enabled on customer network outlined in our User Guides network requirements section`}</li>
      <li parentName="ul">{`Provision Portal deployment setup`}</li>
    </ul>
    <h2>{`Artifactory (BD Tool hosted in AWS)*`}</h2>
    <p>{`Artifactory is an artifact storage application; the place where choco packs are stored.
It’s hosted in AWS as a typcial three tier application:`}</p>
    <ol>
      <li parentName="ol">{`Application Server - EC2 webserver that hosts dl.bd.com`}</li>
      <li parentName="ol">{`Database - Aurora MySQL database that stores package metadata`}</li>
      <li parentName="ol">{`File Store - S3 for actual binary storage (packages and scripts)`}</li>
    </ol>
    <h2>{`Simple Storage Service (S3)*`}</h2>
    <p>{`Binary store for choco packages and scripts.`}</p>
    <h2>{`AppSync*`}</h2>
    <p>{`AWS based API service we use to update status from SSM executions back to Provision Portal.`}</p>
    <h2>{`DynamoDatabase`}</h2>
    <p>{`Database tool we use to store information about servers, devices, and deployments.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      